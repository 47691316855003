import { Component, Host, h, Prop, State, Element, forceUpdate } from '@stencil/core';
import clsx from 'clsx';

@Component({
  tag: 'x-timerangepicker',
  styleUrl: 'x-timerangepicker.sass',
})
export class XTimerangepicker {
  @Element() el: HTMLElement;

  @Prop() name: string;
  @Prop() label: string;
  @Prop() button: string;
  @Prop() disabled: boolean = false;
  @Prop() format: string = 'h:m:s';
  @Prop() from: string = this.format.split(':').fill('00').join(':');
  @Prop() to: string = this.format.split(':').fill('00').join(':');

  @State() value: any = {};
  @State() isOpen: boolean = false;
  @State() isChanged: boolean = false;

  tooltip;

  private parseValue = () => {
    this.value = ['from', 'to'].reduce((result, key) => {
      result[key] = this[key].split(':').map(v => parseInt(v, 10));
      return result;
    }, {});
  };

  private renderValue = key => {
    return this.value[key].map(val => `0${val}`.slice(-2)).join(':');
  };

  private renderFieldValue = () => {
    // Если from и to не заданы, и поле не тронуто юзером, выводим его пустым
    if (!this.isChanged && this.el.getAttribute('from') === null && this.el.getAttribute('to') === null) {
      return '';
    }

    // Если хотя бы одно значенеи задано, выводим интервал.
    // Незаданные при этом будут по умолчанию равны нулям согласно формату
    return `${this.renderValue('from')}-${this.renderValue('to')}`;
  };

  private handleUpdate = e => {
    this.isChanged = true;
    this.value = e.detail;
    this.isOpen = false;
    this.tooltip.hide();
    forceUpdate(this.el);
  };

  connectedCallback() {
    this.parseValue();
  }

  componentDidLoad() {
    this.tooltip.setProps({
      onShow: () => {
        this.isOpen = true;
      },
      onHide: () => {
        this.isOpen = false;
      },
    });
  }

  render() {
    return (
      <Host class={clsx('x-timerangepicker', { '-state-open': this.isOpen })}>
        {!!this.label && <x-field-label disabled={this.disabled}>{this.label}</x-field-label>}
        <x-tooltip trigger="click" theme="ghost" placement="bottom-start" offset="0,4" max-width="236" interactive instance={instance => (this.tooltip = instance)}>
          <x-field-handle name={this.name} value={this.renderFieldValue()} active={this.isOpen} readonly>
            <x-input-adornment position="end" slot="adornment-end">
              <div class="x-timerangepicker__arrow"></div>
            </x-input-adornment>
          </x-field-handle>
          <x-tooltip-content>
            <div class="x-timerangepicker__dropdown">
              <x-timepicker from={this.renderValue('from')} to={this.renderValue('to')} onUpdate={this.handleUpdate} format={this.format} button={this.button}></x-timepicker>
            </div>
          </x-tooltip-content>
        </x-tooltip>
      </Host>
    );
  }
}
