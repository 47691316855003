.x-timerangepicker
  &__dropdown
    background: var(--surface-white)
    box-shadow: var(--surface-shadow)
    border-radius: 6px
    padding: 16px
  &__arrow
    display: block
    color: var(--icon-dark-50)
    width: 0
    height: 0
    border-style: solid
    border-width: 4px 4px 0 4px
    border-color: currentColor transparent transparent transparent

  &.-state-open &
    &__arrow
      transform: rotate(180deg)
      color: var(--primary)
